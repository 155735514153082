.text-zone-about {
  margin-top: 80px;
}

.text-header {
  color: #ffd700;
  font-size: 11px;
  margin-bottom: 3px;
}

.about-page{
  overflow: auto;
}

.aboutText {
  font-size: 17px;
  z-index: 10;
}

.portrait-pic {
  background: #022c43;
  float: right;
  width: 53%;
  height: 115%;
  margin-top: 0.3%;
  z-index: 0;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
  background-color: #022c43;
  z-index: 0;
}

@media screen and (max-width: 1200px) {
  .portrait-pic {
    position: initial;
    float: none;
    margin: 0;
    width: 140%;
    height: 800px;
    z-index: 0;
    offset-anchor: auto;
  }

  .text-zone-about {
    margin-top: -150px;
  }
}